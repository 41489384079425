//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    value: { type: Boolean, default: false },
    transactionId: { type: [String, Number], default: '0' },
    action: { type: String, default: 'cancel' },
  },

  data() {
    return {
      comment: '',
      colorByAction: {
        confirm: 'success',
        complete: 'success',
        cancel: 'warning',
      },
    };
  },

  computed: {
    model: {
      get() {
        const { value } = this;
        return value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  methods: {
    apply() {
      const { transactionId, comment, action } = this;
      this.$emit('apply', { action, id: String(transactionId), comment });
    },
  },
};
