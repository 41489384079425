//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';
import constant from '~/const';
import Filters from '~/components/filters/Index.vue';
import DateRange from '~/components/ui/DateRange.vue';

export default {
  components: { Filters, DateRange },
  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      constant,
      currencies: [{ title: 'WRUB', name: 'Wrapped ruble' }, { title: 'USDT', name: 'Tether' }], // TODO пока с бека не приходят
      filters: {
        userId: '',
        id: '',
        status: [],
        createdFrom: '',
        createdTo: '',
        type: [],
        amountFrom: undefined,
        amountTo: undefined,
        currency: [],
      },
    };
  },

  computed: {
    // ...mapState('tradersRates', ['currencies']), TODO пока с бека не приходят
    ...mapState('tradersDeals', ['filterLists']),
  },

  watch: {
    filters: {
      async handler(data) {
        await this.setFilters(data);
        this.$emit('update');
      },
      deep: true,
    },
  },

  created() {
    this.setFilters({});
  },

  methods: {
    ...mapActions('tradersTransactions', ['setFilters']),
  },
};
