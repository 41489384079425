export default [
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Date',
    },
    cell: {
      type: 'slot',
      name: 'created',
    },
  },
  {
    width: 6,
    header: {
      type: 'text',
      caption: 'UserId',
    },
    cell: {
      type: 'text',
      value: (e) => e.userId,
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Status',
    },
    cell: {
      type: 'text',
      value: (e) => e.status,
    },
  },
];
