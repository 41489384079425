export default [
  {
    width: 8,
    header: {
      type: 'text',
      caption: 'Id',
    },
    cell: {
      type: 'text',
      value: (e) => e.id,
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Date',
    },
    cell: {
      type: 'slot',
      name: 'created',
    },
  },
  {
    width: 22,
    header: {
      type: 'text',
      caption: 'Receiver',
    },
    cell: {
      type: 'text',
      value: (e) => e.receiver,
    },
  },
  {
    width: 6,
    header: {
      type: 'text',
      caption: 'UserId',
    },
    cell: {
      type: 'text',
      value: (e) => e.userId,
    },
  },
  {
    width: 11,
    header: {
      type: 'text',
      caption: 'Amount',
    },
    cell: {
      type: 'text',
      value: (e) => e.amount,
    },
  },
  {
    width: 7,
    header: {
      type: 'text',
      caption: 'Currency',
    },
    cell: {
      type: 'text',
      value: (e) => e.currency,
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Network',
    },
    cell: {
      type: 'text',
      value: (e) => e.network,
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Type',
    },
    cell: {
      type: 'text',
      value: (e) => e.type,
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Status',
    },
    cell: {
      type: 'text',
      value: (e) => e.status,
    },
  },
  {
    width: 6,
    header: {
      type: 'text',
      caption: 'Actions',
    },
    cell: {
      type: 'slot',
      name: 'actions',
    },
  },
];
